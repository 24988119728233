import styled from '@emotion/styled';
import type { JSX } from 'react';

const ContentWrapper = styled.div`
  h2 {
    font-size: 1em;
  }
`;

const ReleaseNotesHeading = styled.div`
  h2 {
    margin: 0;
    font-size: 1em;
  }

  p {
    margin: 0;
  }
`;

const ReleaseNotesContent = (): JSX.Element => {
  return (
    <ContentWrapper>
      <ReleaseNotesHeading>
        <h2>IoT Service Portal 1.10.0</h2>
        <p>2024-06-13</p>
      </ReleaseNotesHeading>
      <h2>New Features</h2>
      <ul>
        <li>Copy of error code text</li>
        <li>Jump to MYiMOW app</li>
      </ul>
      <h2>General Maintenance</h2>
      <ul>
        <li>Update Dependencies</li>
        <li>Additional langauges for DTC catalogue</li>
        <li>Deprecation of IMSI search function</li>
        <li>Accept other STIHL ID business types</li>
        <li>Improve WebSSO login and session stability</li>
      </ul>
      <h2>Bug Fixes</h2>
      <ul>
        <li>Fix of wordings and other typos</li>
      </ul>
    </ContentWrapper>
  );
};

export default ReleaseNotesContent;
