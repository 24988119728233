import type { JSX } from 'react';

/* eslint-disable no-secrets/no-secrets */
export enum StihlDeviceEnvironment {
  Int = 'int',
  Uat = 'uat',
  Prd = 'prd',
}

export type Page = {
  tabs: Tab[];
  meta: Meta;
};

export type Meta = {
  deviceId: string;
  deviceSerialNumber: string;
  deviceModel: DeviceModel;
  deviceType: DeviceType;
  isOffline?: boolean;
  liveStatusErrorMessage?: string;
  hasFleetError?: boolean;
  customerName?: string;
  hasMatchingPreferredDealer: boolean;
  hasCustomerAccess: boolean;
  hasMyiMowAppAccess: boolean;
  isAccountValid: boolean;
  deviceEnvironment?: StihlDeviceEnvironment;
  deviceFlag?: string;
};

export enum AccessLicenses {
  DealerLicense = 'Licence_iServicePortal_Dealer',
  DealerUsLicense = 'Licence_iServicePortal_DealerUS',
  VuLicense = 'Licence_iServicePortal_Subsidiary',
  VuUsLicense = 'Licence_iServicePortal_SubsidiaryUS',
  FirstLevelSupportLicense = 'Licence_iServicePortal_1st_Level',
  SecondLevelSupportLicense = 'Licence_iServicePortal_Headquarter',
  DeveloperLicense = 'Licence_iServicePortal_R&D_imow',
  ConnectedBoxSupportLicense = 'Licence_iServicePortal_R&D_box',
  ReleaseManagerLicense = 'Licence_iServicePortal_Releasemanager',
}

export type Tab = {
  title: string;
  cards: Card[];
  type?: 'SupportFunctions' | 'DiagnosisData';
};

export type Card = {
  title: string;
  type: CardType;
  cardFields: CardField[];
  cardTabs: CardTab[];
  isFullWidth?: boolean;
};

export type CardTab = {
  title: string;
  cardFields: CardField[];
};

export const CardType = {
  Card: 'Card',
  DeviceStatus: 'DeviceStatus',
  CustomerDetails: 'CustomerDetails',
  Settings: 'Settings',
  EventLog: 'EventLog',
  Logs: 'Logs',
  ChangeEnvironment: 'ChangeEnvironment',
  Flags: 'Flags',
  Commands: 'Commands',
  BasicDeviceInformation: 'BasicDeviceInformation',
  DemoMode: 'DemoMode',
  Search: 'Search',
  SearchResult: 'SearchResult',
  DirectHomeDrive: 'DirectHomeDrive',
  OperationData: 'OperationData',
  ProductionAndIdentificationData: 'ProductionAndIdentificationData',
  DiagnosisData: 'DiagnosisData',
  DiagnosisDetailsData: 'DiagnosisDetailsData',
  DtcCodes: 'DtcCodes',
  QualityOfMobileConnection: 'QualityOfMobileConnection',
  ConnectionHistory: 'ConnectionHistory',
  CertificateStatus: 'CertificateStatus',
  Fleetmanagement: 'Fleetmanagement',
};

type CardType = (typeof CardType)[keyof typeof CardType];

export type CardField = {
  title: string;
  isEditable: boolean;
  icon?: CardFieldIcon;
  type: CardFieldType;
  value:
    | ValueType
    | ValueType[]
    | ErrorValue[]
    | ServiceValue[]
    | JobValue
    | StatusValue
    | Customer
    | BaseCustomer
    | DateFieldValue
    | WifiAvailabilityData[]
    | RangeFieldValue
    | null;
};

export type RangeOption = {
  value: number;
  label: string;
};
export type RangeFieldValue = {
  currentValue: number;
  rangeOptions: RangeOption[];
  minIcon?: JSX.Element;
  maxIcon?: JSX.Element;
};

export type DateFieldValue = {
  date: string;
  isDateOnly: boolean;
};

export type BaseCustomer = {
  customerSalutation: string;
  customerName: string;
  customerEmail: string;
};

export type Customer = {
  hasCustomersAssigned: boolean;
  isAccountValid: boolean;
  ownerEmail: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerSalutation: string;
  ownerLanguage: string;
  ownerCountry: string;
  deputies: Deputy[];
  customerId: string;
  preferredDealer: string;
  hasMatchingPreferredDealer: boolean;
  hasError?: boolean;
};

export type Deputy = {
  deputyId: string;
  subscriptionId: string;
  deputyEmail: string;
  deputyFirstName: string;
  deputyLastName: string;
  deputySalutation: string;
  deputyLanguage: string;
  deputyCountry: string;
  hasDeputyMatchingPreferredDealer: boolean;
  isDeputyAccountValid: boolean;
};

export type ErrorValue = {
  code: string;
  requiredAction: string;
  activeSince: string;
  priority?: Priority;
};

export const Priority = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export type Priority = (typeof Priority)[keyof typeof Priority];

export type JobValue = {
  state: string;
  value?: JobStateValue;
};

export type JobStateValue = {
  startTime: string;
  endTime: string;
  mowingZoneId: string;
  isNonCyclic: boolean;
};

export type ServiceValue = {
  code: string;
  activeSince: string;
};

export type StatusValue = {
  state: string;
  trigger: string;
};

export type WarrantyValue = {
  warrantyStart: string;
  warrantyEnd: string;
  warrantyExtensionNumber: number;
};

export type CellularNetworkCard = {
  value: {
    providerId: string;
    connectionType: string;
    currentSignalStrength: number;
  };
  sent: number;
  lastUpdated: number;
};

export type CellularNetworkValue = {
  providerId: string;
  connectionType: string;
  currentSignalStrength: number;
};

export type ValueType = string | number | boolean | Record<string, unknown>;

export const CardFieldType = {
  Text: 'Text',
  Number: 'Number',
  DeviceCurrentArea: 'DeviceCurrentArea',
  DeviceOverallState: 'DeviceOverallState',
  DeviceJobState: 'DeviceJobState',
  DeviceError: 'DeviceError',
  DeviceServiceRequired: 'DeviceServiceRequired',
  Customer: 'Customer',
  BaseCustomer: 'BaseCustomer',
  DeviceGpsPositionValid: 'DeviceGpsPositionValid',
  DeviceGpsPositionUpdated: 'DeviceGpsPositionUpdated',
  DeviceWifiConnection: 'DeviceWifiConnection',
  DeviceCellularConnection: 'DeviceCelullarConnection',
  DeviceCellularNetworkInformation: 'DeviceCellularNetworkInformation',
  DeviceEthernetConnection: 'DeviceEthernetConnection',
  Boolean: 'Boolean',
  Subtitle: 'Subtitle',
  DeviceDemoMode: 'DeviceDemoMode',
  SearchResult: 'SearchResult',
  Date: 'Date',
  Toggle: 'Toggle',
  Percentage: 'Percentage',
  DeviceTotalWorkingTimeOfCuttingMotor: 'DeviceTotalWorkingTimeOfCuttingMotor',
  DeviceTotalWorkingSeconds: 'DeviceTotalWorkingSeconds',
  DeviceTotalDrivenDistance: 'DeviceTotalDrivenDistance',
  DeviceTotalStartedMowingJobs: 'DeviceTotalStartedMowingJobs',
  DeviceTotalNumberOfBumps: 'DeviceTotalNumberOfBumps',
  DeviceTotalWorkingTimeOfCurrentCuttingKnifes:
    'DeviceTotalWorkingTimeOfCurrentCuttingKnifes',
  DeviceWarrantyData: 'DeviceWarrantyData',
  Command: 'Command',
  DeviceWifiAvailability: 'DeviceWifiAvailability',
  Range: 'Range',
  DeviceStatusError: 'DeviceStatusError',
  SoftwarePackageVersionInitial: 'SoftwarePackageVersionInitial',
  DeviceEmbeddedSoftwareHmi: 'DeviceEmbeddedSoftwareHmi',
  DeviceEmbeddedSoftwarePowerPcb: 'DeviceEmbeddedSoftwarePowerPcb',
  DeviceEmbeddedSoftwareDocking: 'DeviceEmbeddedSoftwareDocking',
  DeviceEmbeddedSoftwareSignalPcb: 'DeviceEmbeddedSoftwareSignalPcb',
  TeamMower: 'TeamMower',
  AccessPinCode: 'AccessPinCode',
  PartOfOrganization: 'PartOfOrganization',
  FleetManaged: 'FleetManaged',
} as const;

type CardFieldType = (typeof CardFieldType)[keyof typeof CardFieldType];

export const CardFieldIcon = {
  Heart: 'Heart',
  StateOfCharge: 'StateOfCharge',
  Plug: 'Plug',
  Blades: 'Blades',
  Imow: 'Imow',
  MowingState: 'MowingState',
  Distance: 'Distance',
  Key: 'Key',
  Bluetooth: 'Bluetooth',
} as const;

export type CardFieldIcon = (typeof CardFieldIcon)[keyof typeof CardFieldIcon];

export type LogResponse = {
  title: string;
  downloadUrl: string;
};

export enum DeviceModel {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iMowIA01 = 'IA01',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  iMowIA02 = 'IA02',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  connectedBox = 'CE02',
  Legacy6301 = '6301',
  Legacy6303 = '6303',
  Legacy6309 = '6309',
}

export enum DeviceType {
  Imow3 = 'iMOW 3',
  Imow4 = 'iMOW 4',
  Imow5 = 'iMOW 5',
  Imow6 = 'iMOW 6',
  Imow7 = 'iMOW 7',
  Imow3Evo = 'iMOW 3 Evo',
  Imow4Evo = 'iMOW 4 Evo',
  Imow5Evo = 'iMOW 5 Evo',
  Imow6Evo = 'iMOW 6 Evo',
  Imow7Evo = 'iMOW 7 Evo',
  Imow5Evo4Inch = 'iMOW 5 Evo 4 Inch',
  Imow6Evo4Inch = 'iMOW 6 Evo 4 Inch',
  Imow7Evo4Inch = 'iMOW 7 Evo 4 Inch',
  Imow7Pro = 'iMOW 7 Pro',
  Imow7Pro4Inch = 'iMOW 7 Pro 4 Inch',
  Imow5PlusEvo = 'iMOW 5+ Evo',
  Imow6PlusEvo = 'iMOW 6+ Evo',
  Imow7PlusEvo = 'iMOW 7+ Evo',
  Rmi422 = 'RMI 422',
  Rmi422P = 'RMI 422 P',
  Rmi422Pc = 'RMI 422 PC',
  Rmi522C = 'RMI 522 C',
  Rmi632 = 'RMI 632',
  Rmi632Pc = 'RMI 632 PC',
  ConnectedBoxEuBasic = 'connected Box EU basic',
  ConnectedBoxEuMobile = 'connected Box EU mobile',
  ConnectedBoxUsBasic = 'connected Box US basic',
  ConnectedBoxUsMobile = 'connected Box US mobile',
}

export enum StihlDeviceTag {
  StandardCustomerDevice = 'standardCustomerDevice',
  SimulatedDevice = 'simulatedDevice',
  StihlInternalTestDevice = 'stihlInternalTestDevice',
  StihlDealerTestDevice = 'stihlDealerTestDevice',
  BetaCustomerDevice = 'betaCustomerDevice',
  FriendlyCustomerDevice = 'friendlyCustomerDevice',
  LeasingCustomerDevice = 'leasingCustomerDevice',
  VipCustomerDevice = 'vipCustomerDevice',
  StihlKpDevice = 'stihlKPDevice',
  StihlVpt1Device = 'stihlVPT1Device',
  StihlVpt2Device = 'stihlVPT2Device',
  StihlVpt3Device = 'stihlVPT3Device',
  StihlV1SeriesDevice = 'stihlV1SeriesDevice',
  StihlV2SeriesDevice = 'stihlV2SeriesDevice',
  StihlV3SeriesDevice = 'stihlV3SeriesDevice',
  StihlV4SeriesDevice = 'stihlV4SeriesDevice',
  StihlEseriesDevice = 'stihlESeriesDevice',
  StihlMarketingDevice = 'stihlMarketingDevice',
  StihlFieldTestDevice = 'stihlFieldTestDevice',
}

export enum DeviceConnectionState {
  Connected = 'connected',
  Disconnected = 'disconnected',
  NeverOnlineBefore = 'neverOnlineBefore',
}

export type ItemType<T> = {
  value: T;
  label: string;
};

export type DeviceResult = {
  deviceId?: string;
  deviceModel?: DeviceModel;
  serialNumber?: string;
  deviceType?: DeviceType;
  iotDeviceActivationStatus?: string;
};

export type DiagnosisSessionData = {
  id: string;
  timestamp: string;
  symptoms: string;
  ecus?: EcuData[];
};

export type EcuData = {
  id: string;
  errors: string[];
  data: EcuDetailsData[];
};

export type EcuDetailsData = {
  id: string;
  timeStamp: string;
  name: string;
  valueWithUnit: string;
};

export type WifiAvailabilityData = {
  ssid: string;
  signalStrength: number;
};

export type CertificateStatus = {
  birthValidFrom?: number;
  birthValidTo?: number;
  lifecycleValidFrom?: number;
  lifecycleValidTo?: number;
  diagnosisValidFrom?: number;
  diagnosisValidTo?: number;
};

export type PermissionRequestResponse = {
  id: string;
  deviceId: string;
  state: 'pending' | 'denied' | 'granted' | 'timed_out';
  accessLevel: 'read-only' | 'read-write';
  requestedBy: {
    id: string;
    identityProvider: 'stihlid' | 'websso' | 'azuread';
  };
  expiresAt: string;
};
/* eslint-enable no-secrets/no-secrets */
